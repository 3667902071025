* {
  margin: 0;
  padding: 0;
  color: inherit;
  font-family: "Segoe UI", sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 10px;
}

html,
body {
  overflow: hidden;
}
