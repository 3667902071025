.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #1a1a1a;

  width: 100%;
  height: 100vh;

  color: #f1f1f1;
}
